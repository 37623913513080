<template>
  <div class="home">
    <div class="box-content">
      <div class="left-page">
        <el-form ref="form" :model="formData" label-width="80px">
          <el-form-item   label-width="120px" class="form-item" label="服务器">
            <el-select disabled v-model="host" placeholder="请选择"></el-select>
          </el-form-item>
          <el-form-item label-width="120px" class="form-item" label="语音频道">
            <el-select disabled v-model="voice" placeholder="">
            </el-select>
          </el-form-item>
          <div class="part">
            <div class="title">显示设置</div>
            <el-form-item label-width="120px" class="form-item" label="显示模式">
              <el-select disabled v-model="showType" placeholder="显示模式">
              </el-select>
            </el-form-item>
            <el-form-item label-width="120px" class="form-item" label="只在说话时显示">
              <el-switch disabled v-model="disabled"></el-switch>
            </el-form-item>
            <el-form-item label-width="120px" class="form-item" label="显示昵称">
              <el-switch v-model="formData.showName"></el-switch>
            </el-form-item>
            <el-form-item label-width="120px" class="form-item" label="显示头像">
              <el-switch v-model="formData.showAvatar"></el-switch>
            </el-form-item>
            <el-form-item label-width="120px" class="form-item" label="组合方式">
              <el-select v-model="formData.poisation" placeholder="请选择组合方式">
                <el-option label="头像在上" value="head"></el-option>
                <el-option label="名字在上" value="name"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="part">
            <div class="title">位置设置</div>
            <el-form-item label-width="120px" class="form-item" label="排序方式">
              <el-select v-model="formData.sortOption" placeholder="请选择排序方式">
                <el-option label="横向排序" value="row"></el-option>
                <el-option label="纵向排序" value="column"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label-width="120px" class="form-item" label="显示间距">
              <el-slider class="slider" :max="40" v-model="formData.margin"></el-slider>
            </el-form-item>
          </div>
          <div class="part">
            <div class="title"> 样式设置</div>
            <el-form-item label-width="120px" class="form-item" label="头像大小">
              <el-slider class="slider" :max="500" v-model="formData.headSize"></el-slider>
            </el-form-item>
            <el-form-item label-width="120px" class="form-item" label="昵称字体大小">
              <el-slider class="slider" :max="200" v-model="formData.nameSize"></el-slider>
            </el-form-item>
            <el-form-item label-width="120px" class="form-item" label="昵称字体">
              <el-select disabled v-model="fontStyle" placeholder=""></el-select>
            </el-form-item>
            <el-form-item label-width="120px" class="form-item" label="主题模式">
              <el-select disabled v-model="theme" placeholder=""></el-select>
            </el-form-item>
          </div>
          <div class="part">
            <div class="title">其他样式</div>
            <el-form-item label-width="120px" class="form-item" label="添加说话时气泡">
              <el-switch disabled v-model="delivery"></el-switch>
            </el-form-item>
            <el-form-item label-width="120px" class="form-item" label="添加说话时阴影">
              <el-switch disabled v-model="delivery"></el-switch>
            </el-form-item>
            <el-form-item label-width="120px" class="form-item" label="添加说话发光">
              <el-switch disabled v-model="delivery"></el-switch>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="right-page">
        <div class="content-preview">
          <div class="role-list" :class="formData.sortOption">
            <div class="role"  v-for="item in roleList" :key="item.id" :style="{ width: formData.headSize + 'px', marginRight: formData.margin + 'px',marginBottom:formData.margin + 'px'} ">
              <div class="avatar" :style="{ width: formData.headSize + 'px', height: formData.headSize + 'px', order:formData.poisation==='head'?1:2}" v-show="formData.showAvatar">
              <img src="../assets/images/avatar.jpg" alt="">
              </div>
              <div :style="{ fontSize: formData.nameSize + 'px', order:formData.poisation==='head'?2:1}" v-show="formData.showName" class="name">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HomeView",
  data() {
    return {
      value:'1',
      delivery:true,
      disabled:false,
      host:'猫邮杯Voice+Virtual',
      voice:'主会场/live',
      showType:'头像模式',
      theme:'暗色模式',
      fontStyle:'系统默认',
      formData:{
        showName: true,
        showAvatar: true,
        sortOption:'row',
        margin:40,
        poisation: 'head',
        headSize:179,
        nameSize:18,
      },
      roleList:[
        {id:1,name:'用户 1'},
        {id:2,name:'用户 2'},
        {id:3,name:'用户 3'}
      ]
    }
  }
};
</script>
<style lang="less">
.home {
  width: 100%;
  height: 100%;
  .box-content{
    width: 1700px;
    margin:50px auto 0;
    display: flex;
    justify-content: space-between;
    height: 100%;
    .part{
      border-bottom: 1px solid #ccc;
      .title{
        font-size: 12px;
        color:#ccc;
        padding: 30px 0;
      }
    }
    .form-item{
      display: flex;
      justify-content: space-between;
      text-align: left;
      margin-bottom: 30px;
      font-weight: bold;
      .el-select{
        width: 300px;
      }
      .el-form-item__label{
        text-align: left;
      }
      .slider{
        width: 300px;
      }
    }
    .el-form-item::after, .el-form-item::before{
      content:none;
    }
    .left-page{
      width: 32%;
      // background-color: #000;
    }
    .right-page{
      width: 64%;
      padding-top: 50px;
      .content-preview{
        width: 100%;
        height: 950px;
        background-color: rgba(#ccc,0.5);
        .role-list{
          &.row{
              flex-direction: row;
            }
            &.column{
              flex-direction: column;
            }
            display: flex;
          .role{
            display: flex;
            flex-direction: column;
            .avatar{
              width: 100%;
              height: 150px;
              overflow: hidden;
              border-radius: 50%;
              order:1;
              img{
                width: 100%;
                height: 100%;
              }
            }
            .name{
              order: 2;
              width: 100%;
              text-align: center;
            }

          }
        }
      }
    }
  }
}
</style>
